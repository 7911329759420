<template>
	<div class="form form-horizontal form-scrollable">
		<div class="modal-body">
			<div class="row">
				<div class="col-md-12">
					<div class="btn-group w-100" role="group">
						<button type="button" class="btn ellipsis" :class="{ 'btn-primary': assignedPermissions }" @click="mtd_getAssignedPermissions">
							<i class="fas fa-check"></i> Asignados
						</button>
						<button type="button" class="btn ellipsis" :class="{ 'btn-primary': !assignedPermissions }" @click="mtd_getPermissionsNotAssign">
							<i class="fas fa-times"></i> Sin asignar
						</button>
					</div>
					<div class="table-responsive">
						<table class="table table-striped">
							<thead>
								<tr>
									<th>
										<div class="form-check">
											<input id="customControlInline" v-model="selectAllPermissions" type="checkbox" class="form-check-input" /><label
												for="customControlInline"
												class="form-check-label"
												>#</label
											>
										</div>
									</th>
									<th>Puesto</th>
									<th>Tipo</th>
									<th>Empresa</th>
									<th>Sede</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(user, index) in users" :key="index">
									<td>
										<div class="form-check">
											<input id="customControl" :value="user.id" v-model="selectedPermissions" type="checkbox" class="form-check-input" /><label
												for="customControl"
												class="form-check-label"
												>{{ index + 1 }}</label
											>
										</div>
									</td>
									<td>{{ user.job_position_name }}</td>
									<td>{{ user.type == 1 ? 'Categoría' : 'Subcategoria' }}</td>
									<td>{{ user.company_name }}</td>
									<td>{{ user.campu_name }}</td>
								</tr>
								<tr v-show="users && users.length == 0">
									<td colspan="3" class="table-empty">
										<i>Sin puestos para mostrar</i>
										<i class="far fa-frown"></i>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<small class="text-muted"> *Puestos seleccinados: {{ selectedPermissions.length }}</small>
					<button class="btn btn-primary btn-sm w-100" :disabled="selectedPermissions.length == 0" @click="mtd_removeOrAssign">
						{{ assignedPermissions ? 'Remover puestos' : 'Asignar puestos' }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
	name: 'page-permissions-by-role',
	props: ['propRow'],
	computed: {
		...mapGetters(['url_api']),
	},
	data() {
		return {
			selectAllPermissions: false,
			permissionFilter: '',
			assignedPermissions: true,
			selectedPermissions: [],
			users: [],
		};
	},
	watch: {
		selectAllPermissions(value) {
			if (value) this.mtd_selectAllPermissions();
			else this.selectedPermissions = [];
		},
	},
	created() {
		this.mtd_getAssignedPermissions();
	},
	methods: {
		...mapActions(['get', 'destroy', 'post', 'execute_commit']),

		mtd_removeOrAssign: function () {
			if (this.assignedPermissions) this.mtd_removePermissions();
			else this.mtd_addPermissions();
		},

		mtd_getAssignedPermissions() {
			this.assignedPermissions = true;
			this.get({
				url: `${this.url_api}/job_positions/${this.propRow.id}/sons`,
			}).then(response => {
				this.users = response.data;
				// eslint-disable-next-line no-undef
				$('#sonWindow').modal('show');
			});
		},

		mtd_getPermissionsNotAssign: function () {
			this.assignedPermissions = false;
			this.get({
				url: `${this.url_api}/job_positions/${this.propRow.id}/sons-not-assign`,
				// eslint-disable-next-line no-unused-vars
			}).then(response => {
				this.users = response.data;
			});
		},

		mtd_removePermissions: function () {
			// eslint-disable-next-line no-undef
			alertify.confirm(`¿Deseas <b>remover</b> los puestos seleccionados  ? `, () => {
				this.post({
					url: `${this.url_api}/job_positions/${this.propRow.id}/sons_remove`,
					params: {
						permissions: this.selectedPermissions,
					},
				}).then(() => {
					this.selectAllPermissions = false;
					this.selectedPermissions = [];
					this.mtd_getAssignedPermissions();
					window.message('success', 'Usuarios removidos.', 'Puestos de trabajo');
				});
			});
		},

		mtd_addPermissions: function () {
			this.post({
				url: `${this.url_api}/job_positions/${this.propRow.id}/sons`,
				params: {
					permissions: this.selectedPermissions,
				},
			}).then(() => {
				this.selectAllPermissions = false;
				this.selectedPermissions = [];
				this.mtd_getPermissionsNotAssign();
				window.message('success', 'Nuevos puestos asignados.', 'Puestos de trabajo');
			});
		},

		mtd_selectAllPermissions: function () {
			this.role__permission.forEach(element => {
				this.selectedPermissions.push(element.id);
			});
		},
	},
};
</script>
