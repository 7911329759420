<template>
	<div class="modal-body form form-horizontal form-scrollabl">
		<div class="row">
			<!-- NOMBRE -->
			<div class="col-md-12 form-group">
				<label class="col-form-label label-modal" for="name">Nombre</label>
				<input v-model.trim="job_position.name" type="text" name="name" class="form-control form-control-sm" v-validate="'required'" data-vv-as="'Nombre'" autocomplete="off" />
				<span v-show="errors.has('name')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('name') }} </span>
				<ErrorBackendComponent :errorsBackend="errorsBackend" name="name" />
			</div>
			<!-- TIPO -->
			<div class="col-md-12 form-group" v-if="propInfo.data.method == 'create'">
				<label class="col-form-label label-modal" for="type">Tipo</label>
				<select class="form-control form-control-sm" v-model="job_position.type" v-validate="'required'" data-vv-as="'Nombre'">
					<option :value="null">Seleccionar</option>
					<option value="1">Categoría</option>
					<option value="0">Subcategoria</option>
				</select>
				<span v-show="errors.has('type')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('type') }} </span>
			</div>
			<!-- EMPRESA -->
			<div class="col-md-12 form-group" v-if="propInfo.data.method == 'create'">
				<label class="col-form-label label-modal" for="business_id">Empresa</label>
				<select
					@change="mtd_Businees"
					class="form-control form-control-sm"
					name="enterprise_id"
					v-model="job_position.enterprise_id"
					v-validate="'required'"
					data-vv-as="'Empresa'">
					<option :value="null">Seleccionar</option>
					<option v-for="(item, index) in requirements.enterprises" :key="index" :value="item.id">{{ item.denomination }}</option>
				</select>
				<span v-show="errors.has('enterprise_id')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('enterprise_id') }} </span>
				<ErrorBackendComponent :errorsBackend="errorsBackend" name="enterprise_id" />
			</div>
			<!-- SEDE -->
			<div class="col-md-12 form-group" v-if="propInfo.data.method == 'create'">
				<label class="col-form-label label-modal" for="campu_id">Sede</label>
				<select name="campu_id" v-model="job_position.campu_id" class="form-control form-control-sm" v-validate="'required'" data-vv-as="'Sede'" placeholder="Seleccionar">
					<option :value="null">Seleccionar</option>
					<option :value="item.id" v-for="(item, index) in requirements.campus" :key="index">{{ item.name }}</option>
				</select>
				<span v-show="errors.has('campu_id')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('campu_id') }} </span>
				<ErrorBackendComponent :errorsBackend="errorsBackend" name="campu_id" />
			</div>
		</div>

		<div class="row mt-5">
			<div class="col-12 d-flex justify-content-end">
				<button @click="mtd_send" class="btn btn-primary mr-3">{{ propInfo.data.buttonSubmit }}</button>
				<button @click="mtd_close" class="btn btn-secondary">Cancelar</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
	props: {
		propInfo: {
			required: true,
		},
		modal_id: {
			required: true,
		},
	},
	data() {
		return {
			requirements: {
				enterprises: [],
				campus: [],
			},
			job_position: {
				id: null,
				name: null,
				enterprise_id: null,
				campu_id: null,
				enterprise_denomination: null,
				type: null,
			},
			errorsBackend: {},
			methods: {
				init: {
					create: 'this.mtd_onCreate()',
					edit: 'this.mtd_onEdit()',
				},
				action: {
					create: 'this.mtd_store()',
					edit: 'this.mtd_update()',
				},
			},
		};
	},

	computed: {
		...mapGetters(['url_api']),
		cpd_btnDisabled() {
			return this.errors.any();
		},
	},
	mounted() {
		eval(this.methods.init[this.propInfo.data.method]);
	},
	methods: {
		...mapActions(['get', 'post', 'update']),
		mtd_onCreate() {
			this.mtd_createJobPosition();
		},
		mtd_onEdit() {
			this.mtd_editJobPosition();
		},
		mtd_createJobPosition() {
			this.get({ url: `${this.url_api}/job_position/create` })
				.then(res => {
					if (res.state == 'success') {
						this.requirements.enterprises = res.enterprises;
						this.$emit('onload', { status: 'success' });
					}
				})
				.catch(err => console.log(err));
		},
		mtd_editJobPosition() {
			this.mtd_setJobPosition(this.propInfo.data.row);
			this.$emit('onload', { status: 'success' });
		},
		mtd_setJobPosition(resource) {
			if (resource.id != null) this.job_position.id = resource.id;
			if (resource.name != null) this.job_position.name = resource.name;
			if (resource.enterprise_denomination != null) this.job_position.enterprise_denomination = resource.enterprise_denomination;
		},
		mtd_store: function () {
			this.post({
				url: `${this.url_api}/job_position/store`,
				params: { job_position: this.job_position },
			})
				.then(res => {
					if (res.state == 'success') {
						this.$emit('success', { component: this.propInfo.component, res: { job_position: res.job_position }, action: 'store', modal_id: this.modal_id });
						window.message('success', 'Registro exitoso');
					}
				})
				.catch(errors => (this.errorsBackend = errors));
		},
		mtd_update: function () {
			this.update({
				url: `${this.url_api}/job_position/${this.job_position.id}/update`,
				params: { job_position: this.job_position },
			})
				.then(res => {
					if (res.state == 'success') {
						this.$emit('success', { component: this.propInfo.component, res: { job_position: res.job_position }, action: 'update', modal_id: this.modal_id });
						window.message('success', 'Actualización exitosa');
					}
				})
				.catch(errors => (this.errorsBackend = errors));
		},
		$eval(expr) {
			return eval(expr);
		},
		mtd_close: function () {
			this.$emit('close');
		},
		mtd_send: function () {
			this.submitted = true;
			var _this = this;
			this.$validator.validate().then(valid => {
				if (valid) {
					_this.$eval(_this.methods.action[this.propInfo.data.method]);
				}
			});
		},
		mtd_Businees: function () {
			this.job_position.campu_id = null;
			this.get({
				url: `${this.url_api}/enterprises/${this.job_position.enterprise_id}/campus`,
			})
				.then(res => {
					this.requirements.campus = res.campus;
				})
				.catch();
		},
	},
};
</script>
