<template>
	<div class="row">
		<div class="form-group col-md-4">
			<label for="filter_text">Empresa</label>
			<v-select v-model="enterprise_id" :options="requirements.enterprises" :reduce="enterprise => enterprise.id" label="denomination" placeholder="Seleccionar" />
		</div>
		<div class="form-group col-md-2">
			<label for="filter_text">Tipo</label>
			<select v-model="type" name="type" class="form-control form-control-sm">
				<option value="0">Todos</option>
				<option value="1">Categorias</option>
				<option value="2">Subcategorias</option>
			</select>
		</div>
		<div class="form-group col-md-6">
			<label for="filter_text">Buscar</label>
			<div class="input-group">
				<input v-model="text" @keypress.enter="mtd_executeFilter" class="form-control form-control-sm" id="filter_text" type="text" />
				<button @click="mtd_executeFilter" class="btn btn-primary btn-sm">Filtrar</button>
			</div>
		</div>
	</div>
</template>
<script>
/** Vuex */
import { mapActions, mapGetters } from 'vuex';
export default {
	props: ['prop-execute', 'page'],
	data() {
		return {
			requirements: {
				enterprises: [],
			},
			enterprise_id: null,
			type: 0,
			text: null,
		};
	},
	created() {
		this.mtd_getRequirements();
		this.mtd_executeFilter();
	},
	computed: {
		...mapGetters(['url_api']),
	},
	watch: {
		propExecute: function (newValue) {
			if (newValue) this.mtd_executeFilter();
		},
		page: function (newValue) {
			if (newValue) this.mtd_executeFilter();
		},
	},
	methods: {
		...mapActions(['get', 'execute_commit']),
		mtd_getRequirements() {
			this.get({ url: `${this.url_api}/job_positions/index/filters` })
				.then(res => {
					if (res.state == 'success') {
						this.requirements.enterprises = res.enterprises;
					}
				})
				.catch(err => console.log(err));
		},
		mtd_executeFilter(type = null) {
			let pagination = null;
			type == 'new' ? (pagination = 1) : (pagination = this.page);

			const params = {
				url: `${this.url_api}/job_positions/index?type=${this.type}&page=${pagination}&text=${this.text == null ? '' : this.text}&enterprise_id=${
					this.enterprise_id == null ? '' : this.enterprise_id
				}`,
				mutation: 'JOB_POSITIONS_INDEX__SET_ALL',
			};
			this.get(params);
		},
	},
};
</script>
